.mobile-application-page {
    width: 100%;
    overflow-x: hidden;
  }

  .mobile-app-banner {
    position: relative;
    height: 400px;
    overflow: hidden;
  }
  
  .mobile-app-banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .mobile-app-banner-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .mobile-app-banner-content {
    text-align: center;
  }
  
  .mobile-app-banner-title {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .mobile-app-banner-desc {
    font-size: 1.25rem;
  }
  
  .web-hero-section {
    text-align: center;
    padding: 60px 20px;
  }
  
  .floating-heading {
    font-size: 3rem;
    font-weight: bold;
    color: #1f2937;
    margin-bottom: 1rem;
  }
  
  .web-hero-description {
    font-size: 1.25rem;
    color: #6b7280;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Features Section */
  .features-section {
    padding: 80px 20px;
    background-color: #f9fafb;
  }
  
  .section-header {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 4rem auto;
  }
  
  .section-header h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1f2937;
    margin-bottom: 1rem;
  }
  
  .section-header p {
    font-size: 1.125rem;
    color: #6b7280;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .feature-card {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .feature-icon {
    font-size: 2.5rem;
    color: #3b82f6;
    margin-bottom: 1rem;
  }
  
  .feature-card h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #1f2937;
  }
  
  .feature-card p {
    color: #6b7280;
  }
  
  /* Projects Section */
  .projects-section {
    padding: 80px 20px;
    background: white;
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .project-card {
    background: white;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .project-image-container {
    height: 200px;
    overflow: hidden;
  }
  
  .project-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3sease;
    background-color: #F7F7F7;
}
  
  .project-content {
    padding: 1.5rem;
  }
  
  .project-content h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #1f2937;
  }
  
  .project-content p {
    color: #6b7280;
    margin-bottom: 1rem;
  }
  
  .view-project-btn {
    background: #3b82f6;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    font-weight: 500;
  }
  
  /* Why Choose Us Section */
  .why-choose-us-section {
    padding: 80px 20px;
    background: #f9fafb;
  }
  
  .why-choose-us-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .why-choose-card {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .why-choose-icon {
    font-size: 2.5rem;
    color: #3b82f6;
    margin-bottom: 1rem;
  }
  
  .why-choose-card h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #1f2937;
  }
  
  .why-choose-card p {
    color: #6b7280;
  }
  
  /* CTA Section */
  .cta-section {
    padding: 80px 20px;
    background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
    color: white;
    text-align: center;
  }
  
  .cta-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .cta-content h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .cta-content p {
    font-size: 1.125rem;
    margin-bottom: 2rem;
    opacity: 0.9;
  }
  
  .cta-button {
    background: white;
    color: #3b82f6;
    padding: 1rem 2rem;
    border-radius: 9999px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    font-size: 1.125rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .floating-heading {
      font-size: 2rem;
    }
  
    .section-header h2 {
      font-size: 2rem;
    }
  
    .features-grid,
    .projects-grid,
    .why-choose-us-grid {
      grid-template-columns: 1fr;
    }
  
    .cta-content h2 {
      font-size: 2rem;
    }
  }