/* OrderModal.css */
.order-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(5px);
    z-index: 1000;
  }

  .order-modal-typing {
    color: #ffffff;
    font-size: 1.1rem;
    line-height: 1.6;
    position: relative;
    min-height: 80px;
    transition: opacity 0.5s ease; /* Add this for smooth fading */
  }
  
  .order-modal-container {
    position: fixed;
    top: 10%;
    left: 10%;
    transform: translate(-50%, -50%);
    background: linear-gradient(135deg, #ffffff, #f5f5f5);
    border-radius: 16px;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    width: 85%;
    max-width: 900px;
    z-index: 1001;
    display: flex;
    flex-direction: column;
  }


  @media(max-width:786px){
    .order-modal-container {
      position: fixed;
      top: 2%;
      left: 7%;
    }

  }

  .order-modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    color: #666;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 10;
  }
  
  .order-modal-content {
    display: flex;
    flex-direction: row;
    min-height: 500px;
  }
  
  .order-modal-left {
    flex: 1;
    background: linear-gradient(135deg, #4776E6, #8E54E9);
    padding: 40px 20px 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .order-modal-left::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E"),
    linear-gradient(135deg, #4776E6, #8E54E9);
    opacity: 0.8;
    z-index: 0;
  }
  
  .order-modal-bot-container {
    position: relative;
    z-index: 1;
    margin-top: 40px;
  }
  
  .order-modal-bot {
    background: #ffffff;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  }
  
  .order-modal-bot-icon {
    font-size: 60px;
    color: #6c5ce7;
  }
  
  .order-modal-bot::before {
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    animation: botFloat 3s ease-in-out infinite;
  }
  
  .order-modal-bot::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    animation: botFloat 3s ease-in-out infinite 0.5s;
  }
  
  @keyframes botFloat {
    0%, 100% {
      transform: translateX(-50%) translateY(0);
    }
    50% {
      transform: translateX(-50%) translateY(-10px);
    }
  }
  
  .order-modal-message {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    padding: 20px;
    margin-top: 40px;
    width: 80%;
    position: relative;
    z-index: 1;
    backdrop-filter: blur(5px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  }
  
  .order-modal-typing {
    color: #ffffff;
    font-size: 1.1rem;
    line-height: 1.6;
    position: relative;
    min-height: 80px;
  }
  
  .order-modal-typing::after {
    content: '|';
    position: absolute;
    animation: blink 1s step-start infinite;
  }
  
  @keyframes blink {
    50% { opacity: 0; }
  }
  
  .order-modal-right {
    flex: 1.2;
    padding: 40px;
    display: flex;
    flex-direction: column;
  }
  
  .order-modal-title {
    font-size: 1.8rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    background: linear-gradient(135deg, #4776E6, #8E54E9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .order-modal-pricing {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .order-modal-price {
    font-size: 2.5rem;
    font-weight: 700;
    color: #6c5ce7;
  }
  
  .order-modal-price-text {
    font-size: 1rem;
    color: #666;
    margin-top: 5px;
  }
  
  .order-modal-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .order-modal-form-group {
    display: flex;
    flex-direction: column;
  }
  
  .order-modal-form-group label {
    font-size: 0.9rem;
    font-weight: 600;
    color: #666;
    margin-bottom: 8px;
  }
  
  .order-modal-form-group input {
    padding: 12px 16px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .order-modal-form-group input:focus {
    border-color: #6c5ce7;
    box-shadow: 0 0 0 3px rgba(108, 92, 231, 0.2);
    outline: none;
  }
  
  .order-modal-submit {
    margin-top: 20px;
    padding: 14px 20px;
    background: linear-gradient(135deg, #4776E6, #8E54E9);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .order-modal-submit:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .order-modal-spinner {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  .order-modal-error {
    background-color: #ffebee;
    color: #d32f2f;
    padding: 10px;
    border-radius: 8px;
    font-size: 0.9rem;
    text-align: center;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .order-modal-form {
      display: flex;
      flex-direction: column;
      gap: 5px;
  }
    .order-modal-content {
      flex-direction: column;
    }
    
    .order-modal-left {
      padding: 1px 20px 10px 20px;
      min-height: auto;
    }
    
    .order-modal-bot {
      width: 80px;
      height: 80px;
    }
    
    .order-modal-bot-icon {
      font-size: 40px;
    }
    
    .order-modal-message {
      margin-top: 20px;
      padding: 15px;
    }
    
    .order-modal-typing {
      font-size: 0.9rem;
      min-height: 60px;
    }
    
    .order-modal-right {
      padding: 30px 20px;
    }
    
    .order-modal-title {
      font-size: 1.5rem;
    }
    
    .order-modal-price {
      font-size: 2rem;
    }
  }
  
  /* Animation for robot eyes */
  .order-modal-bot-eyes {
    position: absolute;
    top: 30%;
    width: 60%;
    display: flex;
    justify-content: space-between;
  }
  
  .order-modal-bot-eyes::before,
  .order-modal-bot-eyes::after {
    content: '';
    width: 10px;
    height: 10px;
    background: #333;
    border-radius: 50%;
    animation: blink 3s infinite;
  }