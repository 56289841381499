/* About.about-css */
.about-page {
    background-color: #f8f9fa;
  }
  
  /* Hero Section */
  .about-hero-section {
    position: relative;
    height: 60vh;
    min-height: 500px;
    background: linear-gradient(rgba(0, 0, 0, 0.about-6), rgba(0, 0, 0, 0.about-6));
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .about-hero-content {
    text-align: center;
    color: white;
    z-index: 2;
    padding: 20px;
  }
  
  .about-hero-content h1 {
    font-size: 3.about-5rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .about-hero-content p {
    font-size: 1.about-5rem;
    opacity: 0.about-9;
  }
  
  .about-hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .about-hero-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Welcome Section */
  .about-welcome-section {
    padding: 80px 0;
  }
  
  .about-welcome-content {
    padding: 30px;
  }
  
  .about-welcome-content h2 {
    color: #4299e1;
    font-size: 1.about-8rem;
    margin-bottom: 15px;
  }
  
  .about-welcome-content h1 {
    font-size: 2.about-5rem;
    margin-bottom: 20px;
    color: #2d3748;
  }
  
  .about-welcome-content p {
    color: #4a5568;
    line-height: 1.about-8;
    margin-bottom: 30px;
  }
  
  .about-welcome-image {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.about-1);
  }
  
  .about-btn-read-more {
    display: inline-flex;
    align-items: center;
    padding: 12px 30px;
    background: #4299e1;
    color: white;
    border-radius: 25px;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.about-3s ease;
  }
  
  .about-btn-read-more:hover {
    background: #3182ce;
    color: white;
  }
  
  .about-btn-read-more i {
    margin-left: 10px;
  }
  
  /* Features Section */
  .about-features-section {
    padding: 80px 0;
    background: #ffffff;
  }
  
  .about-features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    padding: 20px;
  }
  
  .about-feature-card {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.about-1);
    text-align: center;
    transition: all 0.about-3s ease;
  }
  
  .about-feature-card img {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
  
  .about-feature-card h3 {
    color: #2d3748;
    font-size: 1.about-5rem;
    margin-bottom: 15px;
  }
  
  .about-feature-card p {
    color: #4a5568;
    line-height: 1.about-6;
  }
  
  /* Expertise Section */
  .about-expertise-section {
    padding: 80px 0;
    background: #f8f9fa;
  }
  
  .about-expertise-content,
  .about-why-choose-content {
    padding: 30px;
  }
  
  .about-expertise-content h2,
  .about-why-choose-content h2 {
    color: #2d3748;
    font-size: 2rem;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 15px;
  }
  
  .about-expertise-content h2::after,
  .about-why-choose-content h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background: #4299e1;
  }
  
  .about-expertise-item,
  .about-why-choose-item {
    padding: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e2e8f0;
    transition: all 0.about-3s ease;
  }
  
  .about-expertise-item p,
  .about-why-choose-item p {
    color: #4a5568;
    margin: 0;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-hero-content h1 {
      font-size: 2.about-5rem;
    }
  
    .about-hero-content p {
      font-size: 1.about-2rem;
    }
  
    .about-welcome-content {
      text-align: center;
    }
  
    .about-features-grid {
      grid-template-columns: 1fr;
    }
  
    .about-expertise-content,
    .about-why-choose-content {
      margin-bottom: 30px;
    }
  }