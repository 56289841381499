/* Clients.css */
.clients-page {
    background-color: #f8f9fa;
    min-height: 100vh;
  }
  
  .portfolio-section {
    padding: 80px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .section-header {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .section-header h2 {
    font-size: 2.5rem;
    color: #2d3748;
    font-weight: 700;
    margin-bottom: 15px;
    position: relative;
    padding-bottom: 15px;
  }
  
  .section-header h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #4299e1;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .section-description {
    color: #718096;
    font-size: 1.1rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .filter-container {
    margin-bottom: 40px;
  }
  
  .filter-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    padding: 0;
    list-style: none;
  }
  
  .filter-button {
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    background-color: #edf2f7;
    color: #4a5568;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .filter-button:hover {
    background-color: #e2e8f0;
  }
  
  .filter-button.active {
    background-color: #4299e1;
    color: white;
  }
  
  .clients-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 30px;
    padding: 20px;
  }
  
  .client-card-wrapper {
    display: flex;
    justify-content: center;
  }
  
  .client-card {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 280px;
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .client-card.with-border {
    border: 1px solid #e2e8f0;
  }
  
  .client-card:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }
  
  .client-card img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 225px;
    max-height: 225px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .portfolio-section {
      padding: 60px 0;
    }
  
    .section-header h2 {
      font-size: 2rem;
    }
  
    .filter-list {
      flex-direction: column;
      align-items: center;
    }
  
    .filter-button {
      width: 200px;
      text-align: center;
    }
  
    .clients-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;
      padding: 10px;
    }
  
    .client-card {
      height: 250px;
    }
  }
  
  /* Animation Classes */
  .fade-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  
  .fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
  }
  
  .fade-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
  }




  /* -----------------------------client relationship ------------------------------------ */


  .relationship-section {
    padding: 100px 0;
    background: linear-gradient(135deg, #f8f9fc 0%, #eaecf4 100%);
    overflow: hidden;
    position: relative;
  }
  
  .section-header {
    margin-bottom: 60px;
  }
  
  .section-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #2e384d;
    margin-bottom: 15px;
  }
  
  .title-underline {
    width: 80px;
    height: 4px;
    background: linear-gradient(90deg, #4e73df, #36b9cc);
    margin: 0 auto 20px;
    border-radius: 2px;
  }
  
  .section-subtitle {
    font-size: 1.1rem;
    color: #6e707e;
    max-width: 650px;
    margin: 0 auto;
  }
  
  .relationship-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
  }
  
  .connection-visualization {
    width: 100%;
    max-width: 500px;
    height: 400px;
    margin-bottom: 40px;
  }
  
  .connection-svg {
    width: 100%;
    height: 100%;
  }
  
  .relationship-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    width: 100%;
    max-width: 1200px;
  }
  
  .relationship-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 30px 25px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
  }
  
  .card-icon-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .card-icon-wrapper i {
    font-size: 24px;
    color: white;
  }
  
  .card-title {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 15px;
    color: #2e384d;
  }
  
  .card-description {
    font-size: 1rem;
    line-height: 1.6;
    color: #6e707e;
    flex-grow: 1;
  }
  
  .card-wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    transform-origin: left;
  }
  
  .testimonial-preview {
    background-color: white;
    border-radius: 15px;
    padding: 40px;
    max-width: 850px;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  }
  
  .quote-mark {
    position: absolute;
    top: -20px;
    left: 40px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(135deg, #4e73df, #36b9cc);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .quote-mark i {
    color: white;
    font-size: 24px;
  }
  
  .testimonial-text {
    font-size: 1.2rem;
    line-height: 1.7;
    color: #2e384d;
    font-style: italic;
    margin-bottom: 25px;
  }
  
  .testimonial-author {
    display: flex;
    align-items: center;
  }
  
  .author-avatar {
    margin-right: 15px;
  }
  
  .avatar-placeholder {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(135deg, #f6c23e, #e74a3b);
  }
  
  .author-info h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 3px;
    color: #2e384d;
  }
  
  .author-info p {
    font-size: 0.9rem;
    color: #6e707e;
    margin: 0;
  }
  
  /* Media queries for responsive design */
  @media (min-width: 992px) {
    .relationship-content {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    
    .connection-visualization {
      width: 40%;
      margin-bottom: 0;
      margin-right: 60px;
    }
    
    .relationship-cards {
      width: 60%;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .relationship-section {
      padding: 70px 0;
    }
    
    .section-title {
      font-size: 2rem;
    }
    
    .testimonial-preview {
      padding: 30px 20px;
    }
    
    .testimonial-text {
      font-size: 1.1rem;
    }
  }