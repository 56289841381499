.about-header {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 20px;
}
.about-top {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.about-top img {
  height: 120px;
  display: flex;
  width: 550px;
}
.about-top h1 {
  font-size: 60px;
  color: black;
  font-weight: bold;
}
.about-main {
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  gap: 20px;
}
.about-left {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 50%;
}
.about-left-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ccd2d8;
  padding: 20px;
  border-radius: 20px;
  gap: 30px;
}
.about-left-text img {
  height: 100px;
}
.about-right {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.about-right p {
  margin-bottom: 0rem;
}

.productengineering-main img {
  height: 80vh;
  width: 100%;
  object-fit: cover;
  margin-top: 50px;
}
.productengineering-main-text {
  height: 1px;
}
.productengineering-main-text h5 {
  position: relative;
  top: -500px;
  color: #fff;
  width: 450px;
  font-size: 40px;
}
.productengineering-main-text p {
  position: relative;
  top: -350px;
  color: #fff;
  width: 450px;
  font-size: 20px;
}

.product-eng-headertext {
  text-align: center;
  margin-top: 20px;
}
.product-eng-headertext p {
  text-align: justify;
  font-size: 20px;
}
.product-Comprehensive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 150px;
  margin-top: 40px;
}
.product-Comprehensive-card1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.product-Comprehensive-card1-dia {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  height: 150px;
  width: 150px;
  border-radius: 35px 0px 35px 0px;
}
.product-Comprehensive-card1 p {
  margin-top: 30px;
  font-size: 20px;
}
.product-Comprehensive-card1-dia img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}
.product-Product-Development {
  text-align: center;
  margin-top: 30px;
}
.product-Product-Development p {
  font-size: 20px;
  margin-bottom: 40px;
}

.product-Product-Development-card1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 250px;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.product-Product-Development-card1 img {
  height: 150px;
  width: 150px;
  object-fit: contain;
}
#Continuous-Deployment img {
  height: 120px;
  width: 120px;
  object-fit: contain;
}
.mvpbtn {
  width: 150px;
  height: 80px;
  border: 5px solid #5aa2d4;
  font-family: "Roboto", serif;
  font-size: 16px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  transition: 1s;
}
.mvpcard {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  align-items: center;
}
.mvpcard-image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -80px;
}
.mvpcard-image img {
  height: 70px;
  width: 100px;
  object-fit: contain;
}
#mvpbtn1 {
  border: 5px solid #55c7ce;
}
#mvpbtn2 {
  border: 5px solid #53ccc2;
}
#mvpbtn3 {
  border: 5px solid #4fc79c;
}

#mvpbtn4 {
  border: 5px solid #4ac06d;
}
#mvpbtn5 {
  border: 5px solid #48bc52;
}
#mvpbtn6 {
  border: 5px solid #5cb447;
}

.mvpbtn::before,
.mvpbtn::after {
  position: absolute;
  background: #ffff;
  z-index: -1;
  transition: 1s;
  content: "";
}

.mvpbtn::before {
  height: 85px;
  width: 100px;
  border-radius: 15px;
}

.mvpbtn::after {
  width: 150px;
  height: 32px;
  border-radius: 7px;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mvpbtn:hover::before {
  width: 0px;
  background: #fff;
}

.mvpbtn:hover::after {
  height: 0px;
  background: #fff;
}

.mvpbtn:hover {
  background: #fff;
}
.heading {
  font-size: 20px;
}
.banner img {
  height: 380px;
  width: 100%;
  object-fit: cover;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .banner img {
    height: 300px;
    width: 100%;
    object-fit: fill;

    margin-top: 0px;
    margin-bottom: 0px;
  }
  .productengineering-main img {
    height: 80vh;
    width: 103.8%;
    object-fit: cover;
    margin-top: 50px;
  }
  .productengineering-main-text h5 {
    position: relative;
    top: -500px;
    color: #fff;
    width: 250px;
    font-size: 30px;
  }
  .productengineering-main-text p {
    position: relative;
    top: -400px;
    color: #fff;
    width: 250px;
    font-size: 20px;
  }
  .product-Comprehensive {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .about-header {
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    margin-bottom: 20px;
  }
  .about-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-top img {
    height: 60px;
    display: none;
  }
  .about-top h1 {
    font-size: 30px;
    color: black;
    font-weight: bold;
  }
  .about-left {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
  }
  .about-main {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    gap: 20px;
  }
  .about-left-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ccd2d8;
    padding: 20px;
    border-radius: 20px;
    gap: 30px;
    text-align: center;
  }
  .about-left-text img {
    height: 100px;
  }
  .about-right {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.ag-format-container {
  width: 1142px;
  margin: 0 auto;
}

.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  padding: 50px 0;
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);

  margin: 0 15px 30px;

  overflow: hidden;

  border-radius: 28px;
}
.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  background-color: #4c49ea;

  overflow: hidden;

  position: relative;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #fff;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_title {
  min-height: 87px;
  margin: 0 0 0px;

  overflow: hidden;

  font-size: 22px;
  color: #fff;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date-box {
  font-size: 18px;
  color: #fff;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #f9b234;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
  background-color: #3ecd5e;
}
.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
  background-color: #e44002;
}
.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
  background-color: #952aff;
}
.ag-courses_item:nth-child(5n) .ag-courses-item_bg {
  background-color: #cd3e94;
}
.ag-courses_item:nth-child(6n) .ag-courses-item_bg {
  background-color: #3ecd5e;
}

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .ag-courses-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }
}
@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;

    font-size: 24px;
  }
  .ag-courses-item_link {
    padding: 22px 40px;
  }
  .ag-courses-item_date-box {
    font-size: 16px;
  }
}
