.digital-marketing {
    width: 100%;
    overflow-x: hidden;
  }
  
  /* Hero Section */
  .hero-section {
    background: linear-gradient(135deg, #efeded 0%, #7c3aed 100%);
    color: white;
    padding: 80px 0;
    min-height: 600px;
    display: flex;
    align-items: center;
  }
  
  .hero-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .hero-text {
    flex: 1;
    max-width: 600px;
  }
  
  .hero-text h1 {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    line-height: 1.2;
  }
  
  .hero-text p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    opacity: 0.9;
  }
  
  .cta-button {
    background-color: white;
    color: #2563eb;
    padding: 1rem 2rem;
    border-radius: 9999px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .hero-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hero-image img {
    max-width: 100%;
    height: auto;
  }
  
  /* Services Section */
  .services-section {
    background-color: #f9fafb;
    padding: 80px 0;
  }
  
  .section-header {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 4rem auto;
    padding: 0 20px;
  }
  
  .section-header h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #1f2937;
  }
  
  .section-header p {
    font-size: 1.125rem;
    color: #6b7280;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .service-card {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .service-icon {
    font-size: 2.5rem;
    color: #2563eb;
    margin-bottom: 1rem;
  }
  
  .service-card h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #1f2937;
  }
  
  .service-card p {
    color: #6b7280;
    line-height: 1.6;
  }
  
  /* Benefits Section */
  .benefits-section {
    padding: 80px 0;
    background: white;
  }
  
  .benefits-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .benefit-card {
    background: linear-gradient(135deg, #eff6ff 0%, #f5f3ff 100%);
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .benefit-icon {
    font-size: 2.5rem;
    color: #7c3aed;
    margin-bottom: 1rem;
  }
  
  .benefit-card h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #1f2937;
  }
  
  .benefit-card p {
    color: #6b7280;
    line-height: 1.6;
  }
  
  .contact-banner {
    background-color: #eff6ff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin: 4rem auto 0;
    max-width: 600px;
  }
  
  .banner-icon {
    font-size: 1.5rem;
    color: #2563eb;
    margin-right: 1rem;
  }
  
  .contact-banner span {
    color: #1e40af;
    font-weight: 600;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .hero-content {
      flex-direction: column;
      text-align: center;
    }
  
    .hero-text {
      margin-bottom: 2rem;
    }
  
    .hero-text h1 {
      font-size: 2.5rem;
    }
  
    .services-grid,
    .benefits-grid {
      grid-template-columns: 1fr;
    }
  
    .contact-banner {
      flex-direction: column;
      text-align: center;
    }
  
    .banner-icon {
      margin: 0 0 1rem 0;
    }
  }