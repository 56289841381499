.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-showcase {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  background-color: #fff; /* Adjust as needed */
  
}

.app-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  background: #f9f9f9;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.app-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(110, 69, 69, 0.1);
}

.app-image {
  width: 100%;
  min-height: 310px;
  max-height: 310px; /* Adjust height as needed */
  object-fit: fill;
}

.app-title {
  padding: 15px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,200&display=swap');

.extralight-italic {
  font-family: 'Josefin Sans', sans-serif; 
  font-weight: 50;
  font-style: italic;
}

.thin-italic {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 100;
  font-style: italic;
}

/* MobileApplication.css */

.mobile-application-page {
  font-family: 'Josefin Sans', sans-serif;
  color: #333;
}

.hero-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #f4f4f4;
}

.hero-section h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.hero-section p {
  font-size: 1.2rem;
  color: #666;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 40px 20px;
}

.project-card {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.project-content {
  padding: 20px;
}

.project-content h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.project-content p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.project-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.project-link:hover {
  background-color: #0056b3;
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.hero-section {
  text-align: center;
  margin-top: 20px;
  animation: floatAnimation 3s ease-in-out infinite; /* Apply floating animation */
}

.hero-section h1 {
  font-size: 2rem;
}

.hero-section p {
  font-size: 1.2rem;
  color: #666;
}


.web-application-page {
  font-family: 'Proxima Nova', sans-serif;
  text-align: center;
  background-color: #f4f4f4;
  padding-bottom: 50px;
}

.web-banner {
  width: 100%;
  height: 380px;
  overflow: hidden;
}

.web-banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.web-hero-section {
  position: relative;
  margin-top: -60px;
  text-align: center;
}

.floating-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  animation: floatAnimation 3s infinite ease-in-out;
}

.web-hero-description {
  font-size: 1.2rem;
  color: #666;
  margin-top: 10px;
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin: 40px auto;
  padding: 20px;
  max-width: 1200px;
}

.project-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: fill;
}

.project-content {
  padding: 15px;
}

.project-content h3 {
  font-size: 1.5rem;
  color: #222;
}

.project-content p {
  font-size: 1rem;
  color: #555;
}

.project-link {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.project-link:hover {
  text-decoration: underline;
}

/* Home.css */
.home-container {
  overflow-x: hidden;
}

/* Hero Section */
.heroslider {
  min-height: 600px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.heroslider::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
}

.hero-title {
  color: white;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

/* Services Section */
.services-section {
  padding: 80px 0;
  background-color: #f8f9fa;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 50px;
}

.service-card {
  padding: 30px;
  border-radius: 20px;
  color: white;
  text-align: center;
  transition: transform 0.3s ease;
}

.service-icon {
  width: 80px;
  height: 80px;
  background: white;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}

.service-icon img {
  height: 50px;
  object-fit: contain;
}

/* Portfolio Section */
.portfolio-section {
  padding: 70px 0px;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 50px;
}

.portfolio-card {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.portfolio-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.portfolio-title {
  padding: 20px;
  margin: 0;
  font-size: 18px;
  text-align: center;
  background: white;
}

/* Values Section */
.values-section {
  padding: 80px 0;
  background-color: #f8f9fa;
}

.value-card {
  padding: 30px;
  text-align: center;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: transform 0.3s ease;
}

.value-icon {
  height: 180px;
  margin-bottom: 20px;
}

/* Common Styles */
.section-title {
  color: hsl(249, 100%, 61%);
  font-weight: 800;
  margin-bottom: 20px;
}

.section-subtitle {
  color: #6c757d;
  margin-bottom: 40px;
}

.section-description {
  font-size: 18px;
  line-height: 1.6;
  color: #6c757d;
}

.btn-get-started {
  display: inline-flex;
  align-items: center;
  padding: 12px 30px;
}

.about-section .section-subtitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #555;
}

.about-section .section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.about-section .section-text {
  font-size: 1rem;
  line-height: 1.7;
  margin-bottom: 2rem;
}

.btn-get-started {
  display: inline-flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  background-color: #0066ff;
  color: white;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.3s ease;
}

.btn-get-started i {
  margin-left: 0.5rem;
}

.btn-get-started:hover {
  background-color: #0056e0;
  transform: translateY(-2px);
}

@media (max-width: 991px) {
  .about-section .row {
    flex-direction: column-reverse;
  }
}

@media (max-width: 786px) {
.value-card-main {
margin-bottom: 70px;
}
}

.new-section{
  background-color:#efeded;
}

@media (min-width: 1200px) {
.container-xl-header {
      max-width: 1215px;
  }
}

.floating-btn {
  position: absolute;
  top: 80%;
  left: 42%;
  /* transform: translate(-50%, -50%); */
  padding: 12px 24px;
  font-size: 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
}

.floating-btn:hover {
  background-color: #0056b3;
}


@media(max-width:786px){
.floating-btn {
  position: absolute;
  top: 80%;
  left: 27%;

}
}