/* Web Application Specific Styles */
.web-app-page {
    width: 100%;
    overflow-x: hidden;
  }
  
  /* Banner Section */
  .web-app-banner {
    position: relative;
    height: 400px;
    overflow: hidden;
  }
  
  .web-app-banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .web-app-banner-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .web-app-banner-content {
    text-align: center;
  }
  
  .web-app-banner-title {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .web-app-banner-desc {
    font-size: 1.25rem;
  }
  
  /* Features Section */
  .web-app-features {
    padding: 5rem 1.25rem;
    background-color: #f9fafb;
  }
  
  .web-app-section-header {
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .web-app-section-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1f2937;
    margin-bottom: 1rem;
  }
  
  .web-app-section-desc {
    font-size: 1.125rem;
    color: #6b7280;
  }
  
  .web-app-features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .web-app-feature-card {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .web-app-feature-icon {
    font-size: 2.5rem;
    color: #3b82f6;
    margin-bottom: 1rem;
  }
  
  .web-app-feature-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #1f2937;
  }
  
  .web-app-feature-desc {
    color: #6b7280;
  }
  
  /* Projects Section */
  .web-app-projects {
    padding: 5rem 1.25rem;
    background: white;
  }
  
  .web-app-projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .web-app-project-card {
    background: white;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .web-app-project-img-container {
    height: 200px;
    overflow: hidden;
  }
  
  .web-app-project-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .web-app-project-content {
    padding: 1.5rem;
  }
  
  .web-app-project-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #1f2937;
  }
  
  .web-app-project-desc {
    color: #6b7280;
    margin-bottom: 1rem;
  }
  
  .web-app-project-btn {
    background: #3b82f6;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  /* Why Choose Us Section */
  .web-app-why-choose {
    padding: 5rem 1.25rem;
    background-color: #f9fafb;
  }
  
  .web-app-why-choose-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .web-app-why-choose-card {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: all 0.3s ease;
  }
  
  /* CTA Section */
  .web-app-cta {
    background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
    padding: 5rem 1.25rem;
    text-align: center;
    color: white;
  }
  
  .web-app-cta-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .web-app-cta-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .web-app-cta-desc {
    font-size: 1.125rem;
    margin-bottom: 2rem;
    opacity: 0.9;
  }
  
  .web-app-cta-btn {
    background: white;
    color: #3b82f6;
    padding: 1rem 2rem;
    border-radius: 9999px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    font-size: 1.125rem;
    transition: all 0.3s ease;
  }
  
  /* Hover Effects */
  .web-app-feature-card:hover,
  .web-app-project-card:hover,
  .web-app-why-choose-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
  
  .web-app-project-card:hover .web-app-project-img {
    transform: scale(1.1);
  }
  
  .web-app-project-btn:hover {
    background: #2563eb;
  }
  
  .web-app-cta-btn:hover {
    transform: scale(1.05);
    background-color: #f8fafc;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .web-app-banner-title {
      font-size: 2.5rem;
    }
    
    .web-app-section-title {
      font-size: 2rem;
    }
    
    .web-app-features-grid,
    .web-app-projects-grid,
    .web-app-why-choose-grid {
      grid-template-columns: 1fr;
    }
    
    .web-app-banner {
      height: 300px;
    }
  }
  
  @media (max-width: 480px) {
    .web-app-banner-title {
      font-size: 2rem;
    }
    
    .web-app-section-title {
      font-size: 1.75rem;
    }
    
    .web-app-feature-card,
    .web-app-why-choose-card {
      padding: 1.5rem;
    }
  }