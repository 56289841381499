/* Contact.css */
.contact-page {
    background-color: #f8f9fa;
  }
  
  /* Hero Section */
  .contact-hero {
    position: relative;
    height: 60vh;
    min-height: 400px;
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .contact-hero-section {
    text-align: center;
    padding: 30px 20px;
}
  
  .hero-content {
    text-align: center;
    color: white;
    z-index: 2;
    padding: 20px;
  }
  
  .hero-content h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .hero-content p {
    font-size: 1.5rem;
    opacity: 0.9;
  }
  
  /* AI Assistant */
  .ai-assistant {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    display: flex;
    align-items: flex-end;
  }
  
  .assistant-avatar {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    background: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .message-bubble {
    position: absolute;
    right: 140px;
    bottom: 60px;
    background: white;
    padding: 15px 20px;
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 250px;
  }
  
  .message-bubble::after {
    content: '';
    position: absolute;
    right: -10px;
    bottom: 20px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid white;
  }
  
  /* Contact Section */
  .contact-section {
    padding: 20px 0;
  }
  
  .info-boxes-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
  }
  
  .info-box {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .info-box i {
    font-size: 2rem;
    color: #667eea;
    margin-bottom: 15px;
  }
  
  .info-box h3 {
    font-size: 1.5rem;
    color: #2d3748;
    margin-bottom: 15px;
  }
  
  .info-box p {
    color: #4a5568;
    margin: 0;
  }
  
  .info-box a {
    color: #667eea;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .info-box a:hover {
    color: #764ba2;
  }
  
  /* Contact Form */
  .contact-form {
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    margin-bottom: 15px;
    transition: all 0.3s ease;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    outline: none;
    border-color: #667eea;
    box-shadow: 0 0 0 3px rgba(102, 126, 234, 0.1);
  }
  
  .contact-form button {
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    color: white;
    padding: 12px 30px;
    border: none;
    border-radius: 25px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .contact-form button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .hero-content h1 {
      font-size: 2.5rem;
    }
  
    .hero-content p {
      font-size: 1.2rem;
    }
  
    .info-boxes-grid {
      grid-template-columns: 1fr;
    }
  
    .ai-assistant {
      bottom: 10px;
      right: 10px;
    }
  
    .assistant-avatar {
      width: 80px;
      height: 80px;
    }
  
    .message-bubble {
      right: 100px;
      bottom: 40px;
      max-width: 200px;
    }
  }